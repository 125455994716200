<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card v-if="noteData">
			<v-card-title v-html="resolvedTitle"></v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<v-autocomplete v-if="showNoteType" v-model="noteData.note_type" :items="$options.noteTypes" label="Note Type *" :disabled="saving" required dense hide-details outlined class="my-3"></v-autocomplete>

				<v-textarea label="Content *" v-model="noteData.content" :disabled="saving" rows="3" required auto-grow outlined dense hide-details class="my-3"></v-textarea>

				<v-menu v-if="showDate" ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="dateFormat" type="datetime-local" label="Date *" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" :disabled="saving" required hide-details dense outlined class="my-3"></v-text-field>
					</template>
					<v-date-picker v-model="dateFormat" no-title scrollable color="primary" @input="menu = false"></v-date-picker>
				</v-menu>

				<email-picker v-model="noteData.mailto" :search-input.sync="search" label="Email User(s)" :disabled="saving" small-chips deletable-chips multiple></email-picker>

			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" :disabled="saving" class="ml-auto">Cancel</v-btn>
				<v-btn v-if="showSkipBtn" @click="modal.trigger('skip close')" :disabled="saving">Skip</v-btn>
				<v-btn color="primary" @click="processForm" :disabled="saving" :loading="saving">
					{{resolvedSaveBtnText}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";
	import { IdType } from "../../utils/IdType";
	import EmailPicker from "@/components/fields/emailPicker";

	export default {
		name: "noteAddEdit",

		components: {EmailPicker},

		props: {
			modal: {
				type: Object,
				required: true,
			},
			noteId: {
				type: IdType,
				default: 0,
			},
			objType: {
				type: String,
			},
			objId: {
				type: [Number, String],
			},
			noteType: {
				type: String,
				default: "internal",
			},
			title: {
				type: [String, Function],
				default: "",
			},
			saveBtnText: {
				type: String,
			},
			populate: {
				type: Object,
				default: () => ({}),
			},
		},

		apollo: {
			noteDetails: {
				query: gql`
					query GetSingleNote($noteId: ID!) {
						noteDetails: note(id: $noteId) {
							id
							object_type
							object_id
							content
							note_type
							date
							mailto
							modified_date
							creation_date
						}
					}
				`,
				variables() {
					return {
						noteId: this.noteId,
					};
				},
				skip() {
					return !this.noteId;
				},
			},
		},

		noteTypes: [
			{
				value: "internal",
				text: "Internal",
			},
			{
				value: "timer",
				text: "Timer",
			},
			{
				value: "status",
				text: "Status",
			},
			{
				value: "document",
				text: "Document",
			},
			{
				value: "client_from",
				text: "From Client",
			},
			{
				value: "client_to",
				text: "To Client",
			},
			{
				value: "deadline",
				text: "Deadline",
			},
			{
				value: "reminder",
				text: "Reminder",
			},
			{
				value: "programming",
				text: "Programming",
			},
			{
				value: "launch",
				text: "Launch",
			},
		],

		data() {
			return {
				noteData: null,
				menu: false,
				search: "",
				modalErrorMessage: false,
				saving: false,
			};
		},

		computed: {
			dateFormat: {
				get() {
					if (!this.noteData) {
						return null;
					}

					return moment(this.noteData.date).format('YYYY-MM-DD[T]hh:mm');
				},
				set(v) {
					this.noteData.date = moment(v).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			resolvedTitle() {
				if (this.title) {
					if (typeof this.title == "string") {
						return this.title;
					} else {
						if (!this.noteData) {
							return null;
						}

						return this.title(this.noteData);
					}
				}

				return this.noteDetails ? "Edit Note" : "Add Note";
			},
			resolvedSaveBtnText() {
				if (!this.saveBtnText) {
					return this.noteId ? "Update" : "Create";
				}

				return this.saveBtnText;
			},
			resolvedNoteType() {
				return this.noteData && this.noteData.note_type;
			},
			showDate() {
				return this.resolvedNoteType != "task_completed";
			},
			showSkipBtn() {
				return this.resolvedNoteType == "task_completed";
			},
			showNoteType() {
				/* if (this.noteId) {
					return false;
				} */

				if (this.resolvedNoteType) {
					return this.resolvedNoteType.indexOf("task") < 0;
				}

				return true;
			},
		},

		methods: {
			processForm() {
				this.saving = true;


				if (this.noteId) {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateNoteArgs!) {
									updateNote(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.noteData
							},
							update: (store, { data: { updateNote } }) => {
								this.saving = false;
								this.$snotify.success(`The ${this.noteData.note_type} has been updated.`, "Success!");
								this.modal.trigger("save close", updateNote);
							},
						})
						.catch((error) => {
							this.saving = false;
							console.error("error updating note", error);
							this.modalErrorMessage = "There was a problem updating the data.";
						});
				} else {
					// new note
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: CreateNoteArgs!) {
									createNote(data: $data) {
										content
										date
										mailto
										object_type
										object_id
										note_type
										author_id
									}
								}
							`,
							variables: {
								data: this.noteData
							},
							update: (store, { data: { createNote } }) => {
								this.saving = false;
								this.$snotify.success(`The ${this.noteData.note_type} has been updated.`, "Success!");
								this.modal.trigger("save close", createNote);
							},
						})
						.catch((error) => {
							this.saving = false;
							console.error("error updating note", error);
							this.modalErrorMessage = "There was a problem updating the data.";
						});
				}
			},

			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				this.noteData = {
					...copyManyFn([
						"note_type",
						"content",
						// "object_type",	// Don't tolerate lowercase object_types
						"object_id",
						"date",
						"mailto",
						"author_id"
					]),
					object_type: this.$options.filters.capitalize(graphQlData.object_type),
					// date: moment(graphQlData.date).format("YYYY-MM-DD"),
				};

				if (this.noteId) {
					this.noteData.id = this.noteId;
				}
			},
		},

		watch: {
			"noteData.mailto"() {
				this.search = "";
			},

			noteId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData(
							Object.assign({}, this.populate, {
								object_type: this.objType,
								object_id: this.objId,
								note_type: this.noteType,
								date: moment().format('YYYY-MM-DD HH:mm:ss'),
								author_id: this.$store.getters.userId
							})
						);
					}
				},
			},

			noteDetails(to) {
				this.copyData(to);
			},
		},
	};
</script>
